import './MobileTopTitleStyles.css'

export default function MobileTopTitle(props){

    return(
        <div>
            <p className='mobileTop'>SOURCES RADIO</p>
        </div>
    )

}