import "./LandingPageStyles.css"
import "../fonts/ABCFavoritMonoEdu-Medium.otf"

export default function LandingPage()
{
    return(
            <div className="landingBackground">
                <div className="center">
                    <p className="sourcesradio">SOURCES RADIO</p>
                </div>
            </div>
   )
}